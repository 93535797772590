var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"student-fee-details"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"6"}},[(_vm.feeDetails)?_c('div',[_c('v-list-item',[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":_vm.feeDetails.user.image}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.feeDetails.user.full_name))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.feeDetails.user.username))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.feeDetails.user.room.sections))])],1)],1),_c('v-card',{staticClass:"mx-4 my-2",attrs:{"color":"primary"}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-list-item',[_c('v-list-item-icon',{staticClass:"align-self-center"},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-clock")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"white--text"},[_vm._v("Next Payment due on")]),_c('v-list-item-subtitle',{staticClass:"white--text font-weight-bold text-h6 text-wrap"},[(_vm.feeDetails.due_date)?_c('span',[_vm._v(_vm._s(_vm.moment(_vm.feeDetails.due_date).format("ddd, Do MMM, YYYY")))]):_c('span',{staticClass:"red--text"},[_vm._v("Not Set")])])],1),(!_vm.currentUser.is_student && (_vm.currentUser.is_admin || _vm.feeDetails.user.room.edit))?_c('v-list-item-icon',{staticClass:"align-self-center"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.createEditFeeDetailsSheet.editId = _vm.feeDetails.user.id;
                    _vm.createEditFeeDetailsSheet.visible = true;}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-pencil")])],1)],1):_vm._e()],1)],1)],1),_c('v-card',{staticClass:"mx-4",attrs:{"elevation":"0"}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-list-item',{staticClass:"px-1"},[_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-title',{staticClass:"font-weight-medium text-h6"},[_vm._v("Total Fees")])],1),_c('v-list-item-action',{staticClass:"ma-0 font-weight-bold text-h6"},[_vm._v("₹ "+_vm._s(_vm.feeDetails.total_fee)+"/-")])],1),_c('v-list-item',{staticClass:"px-1"},[_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-title',{staticClass:"font-weight-medium text-h6 ma-0"},[_vm._v("Total Paid")])],1),_c('v-list-item-action',{staticClass:"font-weight-bold text-h6 ma-0"},[_vm._v("₹ "+_vm._s(_vm.feeDetails.total_paid)+"/-")])],1),_c('v-divider',{staticClass:"my-1"}),_c('v-list-item',{staticClass:"px-1"},[_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-title',{staticClass:"font-weight-medium text-h6"},[_vm._v("Total Balance")])],1),_c('v-list-item-action',{staticClass:"font-weight-bold text-h6 ma-0"},[_vm._v("₹ "+_vm._s(_vm.feeDetails.total_fee - _vm.feeDetails.total_paid)+"/-")])],1)],1)],1)],1):_vm._e(),(_vm.feeDetails)?_c('div',{staticClass:"mt-4"},[_c('p',{staticClass:"mx-4 text-h6 font-weight-bold"},[_vm._v("Payment History")]),_c('intract-smart-list',{ref:"fee-transactions-smart-list",attrs:{"paginated":"","endpoint":_vm.transactionsEndpoint},scopedSlots:_vm._u([{key:"list-item",fn:function(ref){
                    var transaction = ref.item;
return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(transaction.student.full_name))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.moment(transaction.transaction_date).format( "lll" )))]),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(_vm.TextHelper.linkify(transaction.description))}}),_c('v-list-item-subtitle',[_vm._v("Mode of Payment: "+_vm._s(_vm.paymentModes.find( function (m) { return m.value == transaction.transaction_mode; } ).title))])],1),_c('v-list-item-action',{staticClass:"align-self-center"},[_c('div',{staticStyle:{"display":"flex","flex-direction":"row"}},[_c('p',{staticClass:"ma-0 mr-1 font-weight-bold text-h6 primary--text"},[_vm._v(" ₹ "+_vm._s(transaction.paid_amount)+" ")]),_c('intract-drop-down-menu',{attrs:{"options-list":_vm.getDropdownOptions(transaction)}})],1)])],1),_c('v-divider',{staticClass:"mx-4"})]}},{key:"paginated-no-items-found",fn:function(){return [_vm._v("No transactions found")]},proxy:true}],null,false,2354656559)})],1):_vm._e()])],1),_c('intract-create-edit-sheet',{key:"fee-details-create-edit-sheet",ref:"fee-details-create-edit-sheet",attrs:{"title":_vm.createEditFeeDetailsSheet.editId
        ? 'Edit Fee Details'
        : 'Create Fee Details',"description":"Manage your students' fee details with just few clicks","persistent":"","getObject":_vm.finalOperationsOnStudentFeeDetailsObject,"visible":_vm.createEditFeeDetailsSheet.visible,"fields":_vm.feeDetailsFormFields,"endpoint":_vm.endpoints.studentFeesDetailViewSet,"edit-id":_vm.createEditFeeDetailsSheet.editId,"data-object":_vm.createEditFeeDetailsSheet.obj,"create-success-message":_vm.createEditFeeDetailsSheet.createSuccessMessage,"edit-success-message":_vm.createEditFeeDetailsSheet.editSuccessMessage},on:{"close":function($event){_vm.createEditFeeDetailsSheet.selectedUser = null;
      _vm.createEditFeeDetailsSheet.visible = false;
      _vm.createEditFeeDetailsSheet.editId = null;},"objectCreated":_vm.onRefresh,"updateObject":function (obj) { return (_vm.createEditFeeDetailsSheet.obj = obj); }}}),_c('intract-create-edit-sheet',{key:"fee-transaction-create-edit-sheet",ref:"fee-transaction-create-edit-sheet",attrs:{"title":_vm.createEditFeeTransactionSheet.editId
        ? 'Edit Fee Transaction'
        : 'Create Fee Transaction',"description":"Keep track of the transactions happening between the student and your institution","persistent":"","visible":_vm.createEditFeeTransactionSheet.visible,"fields":_vm.feeTransactionFormFields,"endpoint":_vm.endpoints.feeTransactionViewSet,"edit-id":_vm.createEditFeeTransactionSheet.editId,"data-object":_vm.createEditFeeTransactionSheet.obj,"getObject":_vm.finalOperationsOnFeeTransactionObject,"create-success-message":_vm.createEditFeeTransactionSheet.createSuccessMessage,"edit-success-message":_vm.createEditFeeTransactionSheet.editSuccessMessage},on:{"close":function($event){_vm.createEditFeeTransactionSheet.visible = false;
      _vm.createEditFeeTransactionSheet.editId = null;},"objectCreated":_vm.onRefresh,"updateObject":function (obj) { return (_vm.createEditFeeTransactionSheet.obj = obj); }}}),_c('transition',{attrs:{"name":"slide-y-reverse-transition"}},[(!_vm.isLoading && !_vm.currentUser.is_student && (_vm.currentUser.is_admin || _vm.feeDetails && _vm.feeDetails.user.room.edit))?_c('v-btn',{staticClass:"extended-fab non-focused",attrs:{"color":"primary","dark":"","right":"","bottom":"","fixed":"","fab":""},on:{"click":function($event){_vm.createEditFeeTransactionSheet.visible = true}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-plus")]),_vm._v("New Transaction ")],1):_vm._e(),(_vm.currentUser.is_student && _vm.preferences && _vm.preferences.enabled)?_c('v-btn',{staticClass:"extended-fab non-focused",attrs:{"color":"primary","dark":"","right":"","bottom":"","fixed":"","fab":"","to":{ name: 'FeePreferences' }}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-information")]),_vm._v("Payment Details ")],1):_vm._e()],1),_c('confirmation-dialog',{attrs:{"visible":_vm.showDeleteDialog,"title":"Are you sure you want to delete this fee transaction?","description":"This action cannot be undone. This will also send a notification to the student.","delete-dialog":""},on:{"successCallback":_vm.deleteFeeTransaction,"failureCallback":function($event){_vm.showDeleteDialog = false; _vm.toBeDeletedTransaction=null;}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }